import * as React from "react";
import { PageLayout } from "../components/layout/layout";
import Seo from "../components/SEO/seo";
import { SelectionMode } from "../components/selectionMode/selectionMode";

// markup
const IndexPage = () => {
  return (
    <PageLayout>
      <Seo
        title="Trouvez des pneus et jantes pour votre véhicule"
        lang="fr"
        description="Magasin en ligne de pneus et jantes à bas prix"
        meta=""
      />
      <SelectionMode />
    </PageLayout>
  );
};

export default IndexPage;
